module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"323599182114532"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Culture Yard Learn Mandarin","short_name":"Culture Yard","start_url":"/","background_color":"#ffffff","theme_color":"#48AA3A","display":"minimal-ui","icon":"src/images/cy_siteicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"27f49c1ece7c07c28e6151d974c6901c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
